import {
  loadProducts
} from '@/webapi'
/**
 *  Calculate price of custom amount of dominostones
 **/
const COSTS_PER_STONE = 169 / 2000

export default function calcDominoProduct(product, isPostVersand, reductionCode) {
  return new Promise((resolve) => {
    let amount = product.amount
    let genericProductId = `${amount}_dominosteine`

    loadProducts(genericProductId).then(resp => {
      if (resp.data) {
        let genericProduct = resp.data[0]
        if (genericProduct) {
          product.price = genericProduct.price
        } else {
          throw new Error('no product found for amount')
        }
      }
    }).catch(error => {
      calcDominoProductManually(product)
      calcDominoTransportManually(product, isPostVersand)
    }).finally(() => {
      calcReductionPrice(product, reductionCode)
      resolve()
    })
  })
}

function calcDominoProductManually(product) {
  let amount = product.amount
  let dominoprice = round(COSTS_PER_STONE * amount)
  product.price.product = dominoprice
}

function calcDominoTransportManually(product, isPostVersand) {
  let transportprice = isPostVersand ? getTransportCosts(product) : 0
  product.price.transport = transportprice
}

function getTransportCosts(product) {
  let amount = product.amount
  if (amount <= 500) {
    return 10
  } else if (amount <= 1000) {
    return 12
  } else if (amount <= 2000) {
    return 18
  } else {
    return round(amount / 2000 * 18)
  }
}

function calcReductionPrice(product, reductionCode) {
  if(reductionCode) {
    reductionCode = reductionCode.toLowerCase()
  }
  let percent = 0
  if (reductionCode == 'sde20205' || reductionCode == 'school21') {
    percent = 5
  } else if (reductionCode == 'sde20203') {
    percent = 3
  } else if (reductionCode == 'sde202010') {
    percent = 10
  } else if(reductionCode == 'xmas2021' || reductionCode == 'xmas2022') {
    percent = 5
  }
  product.price.reduction = getReduction(product.price.product, percent)
}

function getReduction(price, percent) {
  return round(0 - price / 100 * percent)
}

function round(number) {
  return Math.round(number * 10) / 10
}
