import {
    addToShoppingCart,
    loadShoppingCart,
    updateShoppingCart,
    deleteShoppingCart,
    payShoppingCart,
    voucherShoppingCart
} from '@/webapi'

/**
 * Add products to shopping cart session
 * @param {session id stored in cookies} session_id 
 * @param {product id of product to add} product_id 
 * @param {count of product to add} product_count 
 * @param {additional product data} product_options 
 * @param {result handler of adding} callback 
 */
const add = function (session_id, product_id, product_count, product_options, callback) {
    addToShoppingCart(session_id, {
        product_id: product_id,
        product_count: product_count,
        product_options: product_options
    }).then(resp => {
        let state = resp.data.state
        if (state) {
            let session_id = resp.data.data.session_id
            let session_count = resp.data.data.session_count
            callback({
                state: state,
                session_id: session_id,
                session_count: session_count
            })
        } else {
            callback({
                state: state,
                message: resp.data ? resp.data.message : null
            })
        }
    }).catch(error => {
        console.error(error)
        callback({ state: false })
    })
}

const load = function (session_id, calculate_delivery, callback) {
    loadShoppingCart(session_id, calculate_delivery).then(resp => {
        let state = resp.data.state
        let products = resp.data.data
        callback({ state: state, products: products })
    }).catch(error => {
        console.error(error)
        callback({ state: false })
    })
}

const pay = function (session_id, meta, callback) {
    payShoppingCart(session_id, meta).then(resp => {
        let state = resp.data.state
        let id = resp.data.id
        callback({ state: state, id: id })
    }).catch(error => {
        console.error(error)
        callback({ state: false })
    })
}

const deleteCart = function (session_id, product_id, callback) {
    deleteShoppingCart(session_id, product_id).then(resp => {
        let state = resp.data.state
        let deleted = resp.data.deleted
        callback({ state: state, deleted: deleted })
    }).catch(error => {
        console.error(error)
        callback({ state: false })
    })
}

const update = function (session_id, product_id, product_count, callback) {
    updateShoppingCart(session_id, product_id, product_count).then(resp => {
        let state = resp.data.state
        let message = resp.data.message
        callback({ state: state, message: message })
    }).catch(error => {
        console.error(error)
        callback({ state: false })
    })
}

const applyVoucherCode = function (session_id, voucher, callback) {
    voucherShoppingCart(session_id, voucher).then(resp => {
        let state = resp.data.state
        callback({ state: state })
    }).catch(error => {
        console.error(error)
        callback({ state: false })
    })
}

export {
    add,
    load,
    pay,
    deleteCart,
    update,
    applyVoucherCode
}