<template>
  <div>
    <p style="margin-bottom: 20px">
      <span class="tag is-link is-large">CHF {{ Number(product.price.product).toFixed(2) }}</span>
    </p>
    <b-field label="Anzahl" :message="error">
      <b-numberinput
        v-model="product_count"
        placeholder="1"
        :min="1"
        :max="product.inventory"
        step="1"
        type="is-outlined"
        size="is-medium"
        controls-position="compact"
      ></b-numberinput>
    </b-field>
    <br />
    <div class="buttons">
      <b-button
        label="In den Warenkorb"
        icon-left="cart-outline"
        size="is-medium"
        type="is-primary is-outlined"
        title="Klicken um diesen Artikel in den Warenkorb zu legen"
        :class="{'is-loading': is_loading}"
        @click="add_to_shopping_cart"
      ></b-button>
    </div>
    <div class="notification">
      Anleitungen zur Verwendung von unseren Hilfsmitteln erhalten Sie <a href="https://swissdominoeffect.com/tools" target="_blank">hier</a>.
    </div>
    <div class="notification is-danger" v-if="error">
      {{ error }}
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { add } from "./shopping_cart_helper";
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      product_count: 1,
      error: null,
      bus: new Vue(),
      is_loading: false,
    };
  },
  methods: {
    add_to_shopping_cart() {
      if (!this.validate_inputs()) {
        return;
      }
      this.is_loading = true;
      add(
        this.shopping_cart_session_id,
        this.product.id,
        this.product_count,
        null,
        (resp) => {
          this.is_loading = false;
          if (resp.state) {
            this.error = null;
            this.$cookies.set("sct", resp.session_id, "7d");
            this.update_shopping_cart_count(resp.session_count);
            this.added_to_shopping_cart_dialog();
          } else {
            let message = resp.message ? resp.message : ''
            this.error = `Es ist ein Fehler aufgetreten. ${message}`;
          }
        }
      );
    },
    added_to_shopping_cart_dialog() {
      this.$buefy.dialog.confirm({
        title: "Artikel in den Warenkorb gelegt",
        message: "Der Artikel wurde erfolgreich in den Warenkorb gelegt.",
        cancelText: "Weiter einkaufen",
        confirmText: "Zum Warenkorb",
        type: "is-success",
        hasIcon: true,
        onConfirm: () => this.$router.push({ name: "shopping_cart" }),
        onCancel: () => this.$router.push({ name: "shop" }),
      });
    },
    update_shopping_cart_count(session_count) {
      console.log(session_count);
      this.$cookies.set("scc", Number(session_count), "7d");
      this.bus.$emit("sccrefresh");
    },
    validate_inputs() {
      this.error = null;
      if (this.product_count <= 0) {
        this.error = "Bitte geben Sie eine gültige Anzahl an.";
        return false;
      } else if(this.product_count > Number(this.product.inventory)) {
          this.error = `Es können maximal ${this.product.inventory} Stücke bestellt werden.`
          return false;
      }
      return true;
    },
  },
  computed: {
    shopping_cart_session_id() {
      return this.$cookies.get("sct");
    },
    shopping_cart_count() {
      let count = this.$cookies.get("scc");
      return count ? Number(count) : 0;
    },
  },
};
</script>