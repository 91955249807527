<template>
  <div>
    <div class="content" v-show="!isSummaryMode">
      <h4 class="title is-4">Bestellung aufgeben</h4>
      

      <hr>
      

      <hr>
      <p class="subtitle is-5"><b>Sonstiges</b></p>

     
      <div>
        <p>* Pflichtfelder</p>
      </div>
      <div class="notification is-danger" v-if="validation_error">
        <p>Bitte füllen Sie alle Pflichtfelder (*) aus.</p>
      </div>
      <div class="buttons is-right">
        <button class="button is-primary" @click="togglePage">Weiter zur Bestellübersicht</button>
      </div>
    </div>
    <div class="content" v-show="isSummaryMode && !is_completed">
      <h4 class="title is-4">Bestellübersicht</h4>

      <hr>
      <p class="subtitle is-5"><b>{{order.product.amount}} Dominosteine</b></p>
      <ul>
        <li v-for="color in order.colors" v-bind:key="color.name">
          {{color.count}} x {{color.name}}
        </li>
      </ul>

      <hr>
      <p class="subtitle is-5"><b>Kosten</b></p>
      <table class="table is-striped is-bordered" :key="rerender_counter">
        <tr>
          <th>Position</th>
          <th>Betrag</th>
        </tr>
        <tr>
          <td>{{order.product.name}}</td>
          <td align="right">CHF {{formatNumberToTwoDigits(order.product.price.product)}}</td>
        </tr>
        <tr v-if="isPostVersand">
          <td>Versand per Post</td>
          <td align="right">CHF {{formatNumberToTwoDigits(order.product.price.transport)}}</td>
        </tr>
        <tr v-if="order.product.price.reduction">
          <td>Reduktion</td>
          <td align="right">CHF {{formatNumberToTwoDigits(order.product.price.reduction)}}</td>
        </tr>
        <tr>
          <th>Summe</th>
          <th align="right">CHF {{formatNumberToTwoDigits(sumUpOrder())}}</th>
        </tr>
      </table>

      

      <p v-show="!isPostVersand">
        Die Dominosteine werden nach Zahlungseingang verpackt und anschliessend an der <b>Lageradresse</b>
        bereitgestellt.
      </p>

      <hr>
      

      <hr>
      <p class="subtitle is-5"><b>Lieferfrist</b></p>
      <p v-if="isPostVersand">Ihr Paket wird Ihnen nach Eingang der Zahlung innerhalb von 2-3 Werkstagen per Post
        zugestellt.</p>
      <p v-else>Ihr Paket wird nach Eingang Ihrer Zahlung verpackt und an unserer Lageradresse hinterlegt. Wir
        kontaktieren Sie für die Abholung Ihres Paketes.</p>
      <hr>

      
      <br>

      <div class="notification is-warning" v-show="error_text">
        <p>{{error_text}}</p>
      </div>

      <div class="buttons is-right">
        <button class="button is-light" @click="togglePage">Zurück</button>
        <button class="button is-primary" v-bind:class="{'is-loading': is_loading}" @click="sendOrder">Jetzt
          Dominosteine bestellen</button>
      </div>

      <AGB :shown="agb_shown" />
    </div>
    <div class="content" v-show="is_completed">
      <h4 class="title is-4">Vielen Dank für Ihre Bestellung</h4>

      <hr>
      <p class="subtitle is-5"><b>Wir haben Ihre Bestellung erhalten und senden Ihnen in Kürze eine Bestätigungs-Email.
          Überprüfen Sie bei Nichterhalt Ihren Spam-/Junkordner.</b></p>
      <p v-if="isPostVersand">Sobald Ihre Zahlung bei uns eingegangen ist, werden wir Ihre Bestellung versenden.</p>
      <p v-else>Sobald Ihre Zahlung bei uns eingangen und Ihre Bestellung abholbereit ist, werden wir uns bei Ihnen
        melden.</p>
      <a class="button is-primary is-outlined" v-bind:class="{'is-loading': loading_document}" v-if="created_order_id"
        :href="bill_link"><span class="icon is-small is-left">
          <i class="fas fa-file-pdf"></i>
        </span>&nbsp;Rechnung jetzt herunterladen</a>
      <br><br>
      <router-link class="button is-primary" :to="{name: 'shop'}">Zurück zum Shop</router-link>
    </div>
  </div>
</template>
<script>
  import AGB from '@/views/shop/AGB'
  import calcDominoPrice from '@/business/calculator'
  import {
    pushOrder,
    loadBillDocument
  } from '@/webapi.js'
  export default {
    props: {
      order: Object
    },
    data: function () {
      return {
        metaInfo: {
          title: 'Bestellung'
        },
        isSummaryMode: false,
        
      }
    },
    created() {
      this.calculatePrice()
    },
    methods: {
      formatNumberToTwoDigits(number) {
        return Number(number).toFixed(2)
      },
      togglePage() {
        if (!this.isSummaryMode) {
          this.validation_error = this.validateCustomerData()
          if (this.validation_error) {
            return
          }
        }

        this.isSummaryMode = !this.isSummaryMode
        if (this.isSummaryMode) {
          this.calculatePrice()
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
      },
      
      sendOrder() {
        this.error_text = null
        if (this.agb_verified && !this.is_loading) {
          let orderTransportObject = {
            order: {
              colors: this.order.colors,
              product: this.order.product.id,
              name: this.order.product.name,
              price: this.order.product.price,
              date: new Date()
            },
            customer: this.customer
          }
          this.is_loading = true
          let self = this
          pushOrder(orderTransportObject).then(resp => {
            self.is_loading = false
            let data = resp.data
            self.is_completed = data.state
            if (!data.state) {
              self.error_text = 'Bestellung konnte nicht aufgegeben werden. Bitte nehmen Sie mit uns Kontakt auf.'
            } else {
              self.created_order_id = data.id
              self.loadBill()
            }
          })
        } else {
          this.error_text = 'Bitte bestätigen Sie die AGB\'s.'
        }
      },
      sumUpOrder() {
        let reductionPrice = this.order.product.price.reduction ? Number(this.order.product.price.reduction) : 0
        let pPrice = Number(this.order.product.price.product) + reductionPrice
        let tPrice = Number(this.order.product.price.transport)
        if (this.isPostVersand) {
          return pPrice + tPrice
        }
        return pPrice
      },
      calculatePrice() {
        let self = this
        calcDominoPrice(this.order.product, this.isPostVersand, this.reduction_code).then(() => {
          self.rerender_counter += 1
        })
        if (this.isPostVersand) {
          this.customer.order.payment_method = 'prepayment'
        }
      },
      handleAgbClick() {
        this.agb_shown = !this.agb_shown
      },

    },
    computed: {
      
    },
    components: {
      AGB
    }
  }
</script>