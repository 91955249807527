<template>
  <div>
    <p style="margin-bottom: 30px">
      <span class="tag is-link is-large">CHF {{ product.price.product }}</span>
    </p>
    <div class="notification is-danger" v-if="error">{{ error }}</div>
    <ColorPicker
      :is_loading="is_loading"
      :amount="pick_amount"
      :isAdmin="false"
    />
  </div>
</template>
<script>
import Vue from "vue";
import ColorPicker from "./ColorPicker.vue";
import { add } from "./shopping_cart_helper";
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      error: null,
      bus: new Vue(),
      is_loading: false,
    };
  },
  methods: {
    handleOrder(order, count) {
      if (count <= 0) {
        return;
      }
      const product_count = 1;
      this.is_loading = true;
      add(
        this.session_id,
        this.product.id,
        product_count,
        { colors: order },
        (resp) => {
          this.is_loading = false;
          if (resp.state) {
            this.error = null;
            this.$cookies.set("sct", resp.session_id, "7d");
            this.update_shopping_cart_count(resp.session_count);
            this.added_to_shopping_cart_dialog();
          } else {
            this.error = "Es ist ein Fehler aufgetreten.";
          }
        }
      );
    },
    added_to_shopping_cart_dialog() {
      this.$buefy.dialog.confirm({
        title: "Artikel in den Warenkorb gelegt",
        message: "Der Artikel wurde erfolgreich in den Warenkorb gelegt.",
        cancelText: "Weiter einkaufen",
        confirmText: "Zum Warenkorb",
        type: "is-success",
        hasIcon: true,
        onConfirm: () => this.$router.push({ name: "shopping_cart" }),
        onCancel: () => this.$router.push({ name: "shop" }),
      });
    },
    update_shopping_cart_count(session_count) {
      this.$cookies.set("scc", Number(session_count), "7d");
      this.bus.$emit("sccrefresh");
    },
  },
  computed: {
    pick_amount() {
      return Number(this.product.amount);
    },
    session_id() {
      return this.$cookies.get("sct");
    },
  },
  components: {
    ColorPicker,
  },
};
</script>