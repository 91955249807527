<template>
    <b-modal :active="shown" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
        aria-label="Vermietung anfragen" aria-modal>
        <template>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{form_title}}</p>
                </header>
                <section class="modal-card-body">

                    <b-field :label="organization_name">
                        <b-input v-model="client.organization"></b-input>
                    </b-field>

                    <b-field label="Anrede*" :type="get_error_type('salutation')"
                        :message="get_error_text('salutation')">
                        <b-select placeholder="Anrede auswählen" v-model="client.salutation">
                            <option value="Herr">Herr</option>
                            <option value="Frau">Frau</option>
                        </b-select>
                    </b-field>

                    <b-field label="Vorname*" :type="get_error_type('firstname')"
                        :message="get_error_text('firstname')">
                        <b-input v-model="client.firstname"></b-input>
                    </b-field>

                    <b-field label="Nachname*" :type="get_error_type('surname')" :message="get_error_text('surname')">
                        <b-input v-model="client.surname"></b-input>
                    </b-field>

                    <b-field label="E-Mail*" :type="get_error_type('email')" :message="get_error_text('email')">
                        <b-input type="email" v-model="client.email" placeholder="hans@muster.com" required>
                        </b-input>
                    </b-field>

                    <b-field label="Telefon">
                        <b-input type="telephone" v-model="client.telephone"></b-input>
                    </b-field>

                    <hr>

                    <b-field label="Strasse*" :type="get_error_type('street')" :message="get_error_text('street')">
                        <b-input v-model="client.address.street"></b-input>
                    </b-field>

                    <b-field label="Postleitzahl*" :type="get_error_type('plz')" :message="get_error_text('plz')">
                        <b-input type="number" v-model="client.address.plz"></b-input>
                    </b-field>

                    <b-field label="Ort*" :type="get_error_type('place')" :message="get_error_text('place')">
                        <b-input v-model="client.address.place"></b-input>
                    </b-field>

                    <b-field label="Land*">
                        <b-input v-model="client.address.country" readonly></b-input>
                    </b-field>

                    <b-field label="Bemerkung">
                        <b-input maxlength="500" type="textarea" v-model="addition.description"></b-input>
                    </b-field>
                    <!-- Keine Workshopanfragen mehr vorerst -->
                    <b-checkbox v-model="addition.workshop_interest" v-if="false">Ich bin neben der Miete von Dominos auch an der
                        Durchführung eines Workshops
                        interessiert.</b-checkbox>
                    <p class="is-size-5" style="margin: 10px;">* Pflichfelder</p>
                </section>
                <footer class="modal-card-foot">
                    <b-button label="Abbrechen" @click="close" />
                    <b-button label="Anfrage versenden" type="is-primary" @click="submit" />
                </footer>
                <b-notification :closable="false" v-if="is_loading">
                    <b-loading :is-full-page="true" v-model="is_loading" :can-cancel="false"></b-loading>
                </b-notification>
            </div>
        </template>
    </b-modal>
</template>
<script>
    export default {
        props: {
            shown: {
                type: Boolean,
                required: true
            },
            type: {
                type: String,
                default: 'rent'
            },
            is_loading: {
                type: Boolean,
                required: true
            }
        },
        data: function () {
            return {
                client: {
                    organization: '',
                    salutation: '',
                    firstname: '',
                    surname: '',
                    email: '',
                    telephone: '',
                    address: {
                        street: '',
                        plz: '',
                        place: '',
                        country: 'Schweiz'
                    }
                },
                addition: {
                    description: '',
                    workshop_interest: false
                },
                errors: []
            }
        },
        methods: {
            close() {
                this.$parent.closeSubmitForm()
            },
            submit() {
                if (this.validate()) {
                    this.$parent.handleSubmitForm(this.client, this.addition)
                }
            },
            validate() {
                this.errors = []
                if (!this.client.salutation) {
                    this.errors.push({
                        id: 'salutation',
                        error: 'Bitte geben Sie eine Anrede an.'
                    })
                }
                if (!this.client.firstname) {
                    this.errors.push({
                        id: 'firstname',
                        error: 'Bitte geben Sie einen Vornamen an.'
                    })
                }
                if (!this.client.surname) {
                    this.errors.push({
                        id: 'surname',
                        error: 'Bitte geben Sie einen Nachnamen an.'
                    })
                }
                if (!this.client.email || !this.validateEmail(this.client.email)) {
                    this.errors.push({
                        id: 'email',
                        error: 'Bitte geben Sie eine E-Mail an.'
                    })
                }
                if (!this.client.address.street) {
                    this.errors.push({
                        id: 'street',
                        error: 'Bitte geben Sie eine Strasse an.'
                    })
                }
                if (!this.client.address.plz) {
                    this.errors.push({
                        id: 'plz',
                        error: 'Bitte geben Sie eine Postleitzahl an.'
                    })
                }
                if (!this.client.address.place) {
                    this.errors.push({
                        id: 'place',
                        error: 'Bitte geben Sie eine Ortschaft an.'
                    })
                }
                return this.errors.length == 0
            },
            get_error(id) {
                return this.errors.find(error => error.id == id)
            },
            get_error_type(id) {
                return this.get_error(id) ? 'is-danger' : ''
            },
            get_error_text(id) {
                return this.get_error(id) ? this.get_error(id).error : ''
            },
            validateEmail(email) {
                var re = /\S+@\S+\.\S+/;
                return re.test(email);
            }
        },
        computed: {
            form_title() {
                switch(this.type) {
                    case 'rent':
                        return "Anfrage Dominosteine mieten"
                    case 'course':
                        return "Anfrage Kursangebot"
                    default:
                        return "Anfrage"
                }
            },
            organization_name() {
                switch(this.type) {
                    case 'rent':
                        return "Schule / Unternehmen"
                    case 'course':
                    default:
                        return "Organisation"
                }
            }
        }
    }
</script>