<template>
  <div>
    <div class="modal" v-bind:class="{'is-active' : shown}">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Allgemeine Geschäftsbedingungen</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <AGBContent />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import AGBContent from '@/components/AGBContent'
  export default {
    props: {
      shown: {
        type: Boolean
      },
      handleAgbClick: {
        type: Function,
        required: false
      }
    },
    methods: {
      close() {
        if(this.handleAgbClick) {
          this.handleAgbClick()
        } else {
          this.$parent.handleAgbClick()
        }
      }
    },
    components: {
      AGBContent
    }
  }

</script>

<style>
</style>
