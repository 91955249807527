<template>
  <div>
    <section class="hero is-small is-dark has-background">
      <img
        :src="`./../img/background/domino_wallpaper_blue.png`"
        class="is-transparent hero-background"
      />
      <div class="hero-body">
        <div class="container"><br /><br /></div>
      </div>
    </section>
    <section class="section" v-if="product && !is_loading">
      <div class="container">
        <div class="level">
          <div class="level-left">
            <b-button
              label="Zur Artikelübersicht"
              type="is-link is-outlined"
              size="is-medium"
              icon-left="chevron-left"
              tag="router-link"
              to="/shop"
            />
          </div>
          <div class="level-right">
            <ShoppingCartWidget />
          </div>
        </div>
        <Notification page_id="shop" :canLoad="true" />

        <hr />

        <div class="columns">
          <div class="column is-one-half">
            <img :src="product_image" v-if="product_image" />
            <div v-else-if="product_images">
              <b-carousel
                :indicator-inside="false"
                indicator-custom
                style="margin-bottom: 10px"
              >
                <b-carousel-item v-for="(pic, i) in product_images" :key="i">
                  <span class="image">
                    <img :src="getImage(pic)" />
                  </span>
                </b-carousel-item>
                <template #indicators="props">
                  <span class="al image">
                    <img :src="getImage(product_images[props.i])" />
                  </span>
                </template>
              </b-carousel>
            </div>
          </div>
          <div class="column">
            <h1 class="title" v-if="product">
              {{ product.name }}
            </h1>
            <p class="subtitle" v-html="product.description"></p>
            <div v-if="is_domino">
              <div class="content is-medium">
                <p>Bunte Dominosteine für das Aufbauen von Kettenreaktionen.</p>
                <ul>
                  <li>Bestehend aus eingefärbten MDF-Platten</li>
                  <li>Nachhaltigkeitszertifiziert gemäss Normen FSC® und PEFC<sup>TM</sup></li>
                  <li>Hergestellt in der Schweiz</li>
                </ul>
              </div>
              <hr>
              <DominoConfigurator :product="product" />
            </div>
            <div v-else-if="is_course">
              <CourseConfigurator :course="product" />
            </div>
            <div v-else>
              <DefaultConfigurator :product="product" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" v-else>
      <div class="container">
        <b-skeleton width="200px" height="50px" :animated="true" />
        <hr />
        <div class="columns">
          <div class="column is-one-half">
            <b-skeleton width="100%" height="350px" :animated="true" />
          </div>
          <div class="column">
            <h1 class="title">
              <b-skeleton width="70%" height="40px" :animated="true" />
            </h1>
            <p class="subtitle">
              <b-skeleton
                width="100%"
                height="20px"
                :animated="true"
                v-for="index of 4"
                :key="index"
              />
            </p>
            <b-skeleton width="200px" height="50px" :animated="true" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { loadProducts } from "@/webapi.js";
import ColorPicker from "./ColorPicker";
import Order from "./Order";
import Notification from "@/components/Notification";
import CourseConfigurator from "./CourseConfigurator";
import DefaultConfigurator from "./DefaultConfigurator";
import DominoConfigurator from "./DominoConfigurator";
import ShoppingCartWidget from "./ShoppingCartWidget";
export default {
  metaInfo: function () {
    return {
      title: this.product ? this.product.name : "Domino",
    };
  },
  data: function () {
    return {
      product: null,
      order: null,
      count: 1,
      is_loading: false,
    };
  },
  created() {
    this.handleAdminOrderInit();
    if (!this.product) {
      this.is_loading = true;
      loadProducts(this.productId).then((resp) => {
        if (resp.data) {
          let product = resp.data.filter((prod) => prod.id == this.productId);
          this.product = product[0];
          this.$route.meta.title = this.product;
        }
        this.is_loading = false;
      });
    }
  },
  methods: {
    handleOrder: function (colors, count) {
      this.order = {
        product: this.product,
        colors: colors,
      };
      if (this.isAdminOrder) {
        this.order.product.amount = count;
        this.order.product.name = `${count} Dominosteine`;
      }
    },
    handleAdminOrderInit() {
      if (this.isAdminOrder) {
        this.product = {
          amount: 100000,
          id: "custom_dominosteine",
          image: "d_custom.png",
          name: "Dominosteine",
          price: {
            product: -1,
            transport: -1,
          },
        };
      }
    },
    getImage(id) {
      return `./../img/shop/${id}`;
    },
  },
  computed: {
    isColorPickingMode() {
      return this.product && !this.order;
    },
    productId() {
      return this.$route.params.product;
    },
    isAdminOrder() {
      return this.productId == "dominosteine";
    },
    productAmount() {
      if (this.isAdminOrder) {
        return 100000;
      }
      return this.product.amount;
    },
    is_domino() {
      return this.product.type == "dominoes";
    },
    is_course() {
      return this.product.type == "course";
    },
    product_image() {
      if (this.product.image) {
        return this.getImage(this.product.image);
      }
      return null;
    },
    product_images() {
      return this.product.images;
    },
  },
  components: {
    ColorPicker,
    Order,
    Notification,
    CourseConfigurator,
    DefaultConfigurator,
    DominoConfigurator,
    ShoppingCartWidget,
  },
};
</script>