<template>
  <div>
    <div class="content">
      <h4 class="title is-4">Farben auswählen</h4>
      <p class="subtitle">Wählen Sie jetzt Ihre Farben aus.</p>
      <div v-if="isLoading">
        <progress class="progress is-small is-primary" max="100"></progress>
      </div>
      <div class="columns is-multiline" v-else>
        <div
          class="column is-4"
          v-for="color in colors"
          v-bind:key="color.name"
          v-show="color.available"
        >
          <input
            type="number"
            class="input nonv"
            :style="`background-color: ${color.color};  text-align: right; color: white;`"
            :class="{ 'coming-soon': color.soon }"
            :title="color.soon ? 'Bald wieder verfügbar' : color.name"
            v-model="color.amount"
            step="50"
            min="0"
            :disabled="color.soon"
            @keydown="handleKeyDown"
          />
        </div>
        <div class="column is-4 has-text-centered">
          <b>{{ currentCount }} gewählt</b>
        </div>
        <div class="column is-12">
          <button
            class="button is-primary is-medium"
            @click="order"
            :class="{ 'is-loading': is_loading, 'disabled-btn': !isSelectedOk }"
          >
            Zum Warenkorb hinzufügen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.coming-soon {
  opacity: 0.4;
}
.disabled-btn {
  opacity: 0.5;
}
</style>
<script>
import { loadDominoColors } from "@/webapi.js";
export default {
  data: function () {
    return {
      colors: [],
      isLoading: false,
    };
  },
  props: {
    amount: Number,
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
    is_loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    let self = this;
    this.isLoading = true;
    loadDominoColors().then((resp) => {
      let colorList = resp.data;
      colorList.forEach((color) => (color.amount = 0));
      self.colors = colorList;
      self.isLoading = false;
    });
  },
  methods: {
    order() {
      if (this.isSelectedOk) {
        let colors = this.mapSelection();
        this.$parent.handleOrder(colors, this.currentCount);
      }
    },
    mapSelection() {
      let selection = [];
      this.colors.forEach((color) => {
        if (color.available && color.amount > 0) {
          selection.push({
            name: color.name,
            count: color.amount,
          });
        }
      });
      return selection;
    },
    handleKeyDown(event) {
      if (
        !(
          (event.keyCode > 95 && event.keyCode < 106) ||
          (event.keyCode > 47 && event.keyCode < 58) ||
          event.keyCode == 8
        )
      ) {
        event.preventDefault();
      }
    },
  },
  computed: {
    currentCount() {
      let actualAmount = 0;
      this.colors.forEach((color) => (actualAmount += Number(color.amount)));
      return actualAmount;
    },
    isSelectedOk() {
      if (this.isAdmin) {
        return true;
      }
      let expectedAmount = this.amount;
      let actualAmount = this.currentCount;
      return expectedAmount == actualAmount;
    },
  },
};
</script>
